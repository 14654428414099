<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Phase: <small>{{ phase.title }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group text-left">
      <label for="phase_title">Phase Title</label>
      <input type="text" class="form-control" id="phase_title" v-model="phase.title" placeholder="Title">
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'PhaseIndex' }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="createPhase" type="button" class="btn btn-success">Create Phase</button>
      </div>
    </div>

    <br>

  </div>
</template>

<script>
import { PhaseService } from '@/common/services/phase.service';
import DangerAlert from '@/common/components/danger-alert.vue';

export default {
  name: 'LanguageNew',
  components: {
    'danger-alert': DangerAlert,
  },
  data() {
    return {
      phase: {
        title: '',
      },
      errors: [],
    };
  },
  methods: {
    createPhase() {
      PhaseService.create(this.phase)
        .then(({ data }) => {
          this.$router.push({ name: 'PhaseShow', params: { id: data.id } }, () => {
            this.$notify({
              title: 'Created Phase',
              text: `Successfully created ${data.title}!`,
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/phases";
</style>
